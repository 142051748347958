
.total {
	width: 100%;
	height: 100%;
	border-radius: 13px;
	overflow-x: hidden;
	opacity: 1;
	transition: opacity 1.25s;
	cursor: pointer;

	div {
		height: inherit;
		margin-left: 2px;
	}
}

.total:hover {
	.player-icon {
		opacity: 0.8 !important;
	}

	.duration {
		opacity: 0;
	}
}

.total .player-icon {
	transition: all 1.25s;
	opacity: 0;
	position: absolute;
	top: 7px;
	transform: scale(0.85);
}

.total .play-icon,
.total .pause-icon {
	transition: all 1.25s;
	transform: translateX(-50%) scale(0.55);
	top: -2px;
	left: 50%;
	opacity: 0;
}

.pause-icon:hover {
	transform: translateX(-50%) scale(0.605) !important;
	opacity: 1;
}

.total .play-icon {
	transform: translateX(-50%) scale(0.6);
}

.play-icon:hover {
	transform: translateX(-50%) scale(0.65) !important;
}

.total .play-icon:hover {
	transform: scale(0.93);
	opacity: 1;
}

.total .volume-down {
	left: 15px;
}

.total .volume-down:hover {
	transform: scale(0.93);
	opacity: 1;
}

.total .volume-up {
	right: 15px;
	top: 5px;
}

.total .volume-up:hover {
	transform: scale(0.93);
	opacity: 1;
}

.current {
	background-color: rgba(0, 0, 0, 0.15);
	transition: all 0.5s;
}

.current__rod {
	height: 100%;
	width: 2px;
	background-color: rgba(255, 255, 255, 0.45);
	width: 1px;
	float: right;
	position: relative;
	cursor: col-resize;
}

.duration {
	transition: all 1.25s;
	pointer-events: none;
	position: absolute;
	right: 0;
	line-height: 100%;
	display: flex;
	font-weight: 200;
	line-height: 100%;
	display: flex;
	padding-right: 9px;
	align-items: center;
	user-select: none;
}

.hidden {
	opacity: 0;
	transition: none;
}

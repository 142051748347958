
.description-wrapper {
	margin-top: 20px;
	text-align: center;
}

.album-description {
	user-select: none;
	font-weight: 200;
	cursor: default;
	line-height: 125%;
	font-size: 1.15em;
}

.album-links-wrapper {
	display: flex;
	gap: 8px;
	justify-content: center;
	margin-bottom: 40px;
}

.album-links {
	display: flex;
	justify-content: center;
	align-items: baseline;
	gap: 8px;
	width: 25px;
	flex-wrap: wrap;
	position: relative;
	top: 8px;
	height: 29px;
	overflow: hidden;
}

.links-intro {
	margin-top: 10px;
	text-decoration: underline;
	font-weight: 200;
	user-select: none;
	cursor: default;
}

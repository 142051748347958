
.contacts {
	cursor: default;
	border-radius: 0px 0px 22px 22px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	background: linear-gradient(202deg, rgba(23, 43, 72, 0.5) -28.21%, rgba(69, 72, 88, 0.5) 120.37%);
	font-size: 1.25em;
	font-weight: 200;
	padding: 10px 20px;
	display: flex;
	flex-direction: column;
	gap: 2px;
	left: 50%;
	transform: translateX(-50%);
	top: 60px;
	backdrop-filter: blur(1px);
}

.contact-wrapper {
	display: flex;
}

.contact-type {
	max-width: 17%;
}

.contact-link {
	cursor: pointer;
	margin: 0 0 0 20px;
	color: white;
	text-decoration: none;
	transition: all 0.7s;

	&:hover {
		text-decoration: underline;
	}
}

.close-button {
	position: absolute;
	bottom: 6px;
	right: 11px;
	transform: scale(0.9);
	transition: all 0.7s;

	&:hover {
		transform: scale(0.95);
	}
}

.workshop {
	border-radius: 0px 0px 22px 22px;
	border: 1px solid rgba(255, 255, 255, 0.8);
	background: linear-gradient(
		79deg,
		rgba(98, 98, 98, 0.9) 2.57%,
		rgba(130, 130, 130, 0.9) 27.57%,
		rgba(81, 81, 81, 0.9) 76.58%,
		rgba(102, 102, 102, 0.9) 96.68%
	);
	display: flex;
	flex-direction: column;
	gap: 2px;
	left: 50%;
	transform: translateX(-50%);
	top: 60px;
	backdrop-filter: blur(1px);
}

.workshop-wrapper {
	max-width: 600px;
	min-width: 600px;
	margin: 76px;
}

.workshop-image {
	border-radius: 0px 0px 6px 6px;
	min-height: 450px;
	max-height: 450px;
	max-width: 600px;
	width: auto;
	cursor: pointer;
}

.image-name {
	font-size: 1.15em;
	position: absolute;
	margin-top: 20px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 1.15em;
}

.workshop-wrapper-inner {
	display: flex;
	justify-content: center;

	button {
		position: absolute;
		top: 50%;
		z-index: 70000;
	}

	.prev {
		left: 25px;

		&:active {
			position: absolute;
			top: calc(50% - 1px);
		}
	}

	.next {
		right: 25px;

		&:active {
			position: absolute;
			top: calc(50% - 1px);
		}
	}
}

.small {
	width: 23px;
	transition: all 1s;

	&:hover {
		transform: scale(1.1);
	}
}

@media (max-width: 975px) {
	.workshop {
		top: 30px;
		transform: translateX(-50%) scale(0.9);
	}
}

@media (max-width: 915px) {
	.workshop {
		top: 0;
		transform: translateX(-50%) scale(0.8);
	}
}

@media (max-width: 830px) {
	.workshop {
		top: -30px;
		transform: translateX(-50%) scale(0.7);
	}

	.image-name {
		transform: translateX(-50%) scale(1.3);
	}
}

@media (max-width: 750px) {
	.workshop {
		transform: translateX(-50%) scale(0.8);
		top: 0;
		position: fixed !important;
	}
}

@media (max-width: 600px) {
	.workshop {
		top: -30px;
		transform: translateX(-50%) scale(0.7);
	}
}

@media (max-width: 530px) {
	.workshop {
		top: -15px;
	}

	.workshop-image {
		max-height: 350px;
		min-height: 350px;
	}

	.workshop-btn.prev {
		left: 60px;
	}

	.workshop-btn.next {
		right: 60px;
	}
}

@media (max-width: 505px) {
	.workshop {
		top: 45px;
	}
}

@media (max-width: 495px) {
	.workshop-btn.prev {
		left: 70px;
	}

	.workshop-btn.next {
		right: 70px;
	}
}

@media (max-width: 475px) {
	.workshop-btn.prev {
		left: 75px;
	}

	.workshop-btn.next {
		right: 75px;
	}
}

@media (max-width: 460px) {
	.workshop-btn.prev {
		left: 80px;
	}

	.workshop-btn.next {
		right: 80px;
	}
}

@media (max-width: 455px) {
	.workshop-btn.prev {
		left: 85px;
	}

	.workshop-btn.next {
		right: 85px;
	}
}

@media (max-width: 435px) {
	.workshop-btn.prev {
		left: 95px;
	}

	.workshop-btn.next {
		right: 95px;
	}
}

@media (max-width: 425px) {
	.workshop {
		top: 52px;
	}

	.workshop-image {
		max-height: 300px;
		min-height: 300px;
	}

	.workshop-btn.prev {
		left: 115px;
	}

	.workshop-btn.next {
		right: 115px;
	}
}

@media (max-width: 410px) {
	.workshop-btn.prev {
		left: 120px;
	}

	.workshop-btn.next {
		right: 120px;
	}
}

@media (max-width: 400px) {
	.workshop-btn.prev {
		left: 125px;
	}

	.workshop-btn.next {
		right: 125px;
	}
}

@media (max-width: 375px) {
	.workshop {
		top: 57px;
	}

	.workshop-image {
		max-height: 270px;
		min-height: 270px;
	}

	.workshop-btn.prev {
		left: 140px;
	}

	.workshop-btn.next {
		right: 140px;
	}
}

@media (max-width: 360px) {
	.workshop-btn.prev {
		left: 145px;
	}

	.workshop-btn.next {
		right: 145px;
	}
}

@media (max-width: 345px) {
	.workshop-btn.prev {
		left: 150px;
	}

	.workshop-btn.next {
		right: 150px;
	}
}

@media (max-width: 335px) {
	.workshop-btn.prev {
		left: 155px;
	}

	.workshop-btn.next {
		right: 155px;
	}
}

@media (max-width: 325px) {
	.workshop {
		top: 59px;
	}

	.workshop-image {
		max-height: 250px;
		min-height: 250px;
	}

	.workshop-btn.prev {
		left: 162px;
	}

	.workshop-btn.next {
		right: 162px;
	}
}

@media (max-width: 320px) {
	.workshop-btn.prev {
		left: 165px;
	}

	.workshop-btn.next {
		right: 165px;
	}
}


.player-icon {
	width: 30px;
	opacity: 0.5;
	user-select: none;
	transition: all 0.5s;
	z-index: 200;

	&_big {
		width: 32px;
		height: 36px;
	}

	&:active {
		position: relative;
		top: 56px;
	}
}

@media (hover: hover) {
	.player-icon {
		&:hover,
		&:active {
			opacity: 1;
		}
	}
}

.close {
	position: fixed;
	height: 27px;
	top: 36px;
	right: 36px;

	&:active {
		position: fixed;
		top: 35px;
	}
}

.prev,
.next {
	top: 55px;
}

.play {
	margin-left: 3px;
	margin-top: 2px;
}

.icon-play-hovered svg {
	width: 25px !important;
}

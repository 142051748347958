
.player {
	align-items: end;
	margin-top: 20px;
	user-select: none;
	display: flex;
	gap: 20px;

	.prev,
	.next {
		transform: scale(0.8);
		position: relative;

		&:hover {
			transform: scale(0.88);
		}
	}
}

.player__controls {
	position: relative;
	border-radius: 14px;
	border: 1px solid #fff;
	background: linear-gradient(
		345deg,
		rgba(98, 98, 98, 0.8) 0%,
		rgba(130, 130, 130, 0.8) 26.56%,
		rgba(81, 81, 81, 0.8) 78.65%,
		rgba(102, 102, 102, 0.8) 100%
	);
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	min-height: 36px;
	min-width: 70px;
	margin-top: 20px;
	transition: all 3s;

	&:hover {
		.play {
			opacity: 0 !important;
		}

		.play-title {
			opacity: 1;
			user-select: none;
			cursor: pointer;
		}
	}
}

.progress-bar-activated {
	min-width: 192px;
}

.play-title {
	transition: all 1s;
	text-align: center;
	position: absolute;
	top: 48%;
	font-weight: 200;
	opacity: 0;
	left: 50%;
	transform: translate(-50%, -50%);
}

.main-btn {
	width: 17px;
	padding: 10px 0;
	opacity: 1;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	top: 50%;
	transition: all 1s;

	&:active {
		position: absolute;
		top: 50%;
	}
}

.visible {
	opacity: 1;
}

.invisible {
	opacity: 0;
}

.your-album-btn {
	text-decoration: none;
	color: white;
	font-weight: 300;
	transition: all 0.7s;

	&:hover {
		transform: scale(1.05);
	}
}

@media (max-width: 840px) {
	.shifted {
		top: 25px;
	}

	.shifted:active {
		top: 26px;
	}
}

@media (max-width: 690px) {
	.shifted {
		top: -80px;
	}

	.shifted:active {
		top: -79px;
	}
}

@media (max-width: 350px) {
	.player {
		gap: 3vw;
	}
}


.load-screen {
	background: rgba(0, 0, 0, 0.15);
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.artist-titles {
	font-family: Tangerine;
	font-size: 100px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-align: center;
	display: flex;
	flex-direction: column;

	.artist-name {
		text-shadow: none;
		background-size: 200% 100%;
		background-clip: text;
		color: transparent;
		background-image: linear-gradient(90deg, white 50%, black 50%);
		animation: color-change 3s ease-in-out;
		font-size: 23vw;
		padding: 10px;
	}
}

@keyframes color-change {
	from {
		background-position: 100%;
	}
	to {
		background-position: 0%;
	}
}


main {
	height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
}

.content {
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
}

.main-titles {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 110px;
}

.main-title {
	font-size: 5.1em;
	cursor: default;
}

.main-subtitle {
	font-size: 1.3em;
	position: relative;
	top: -15px;
	cursor: default;
}

.main-subtitle::before,
.main-subtitle::after {
	background-image: url('/public/assets/icons/star.svg');
	background-repeat: no-repeat;
	background-size: 16px 16px;
	content: '';
	display: inline-block;
	height: 16px;
	margin: 0 5px;
	position: relative;
	top: 2px;
	width: 16px;
}

.main-subtitle::after {
	margin: 0 4px;
}

@media (max-width: 505px) {
	.main-titles {
		margin-top: 160px;
	}
}

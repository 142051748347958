
.player-item {
	transition: all 0.5s;

	&:hover {
		transform: scale(1.02);
		.no-album-description {
			opacity: 1;
		}
	}
}

.no-album-description {
	transition: all 1.5s;
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	line-height: 125%;
	text-transform: uppercase;
	top: 48% !important;
	opacity: 0.75;
	font-weight: 350;
	pointer-events: none;
}

.main .no-album-description {
	font-size: 2em;
}

.diff1 .no-album-description {
	font-size: 1.5em;
}

.unreleased-album {
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	line-height: 125%;
	text-transform: uppercase;
	top: 70% !important;
	width: 100%;
	padding: 10px 0;
	font-weight: 400;
	color: black;
	background: white;
}

.main .unreleased-album {
	font-size: 1.75em;
}

.diff2 .unreleased-album {
	color: white;
	background: transparent;
}

.paused img {
	filter: brightness(85%);
}

.album .image {
	background: linear-gradient(193deg, #556a8984 0%, #555e8a7a 100%);
	background-size: cover;
}

.side-album {
	display: none;
	margin: 0 !important;
	filter: brightness(90%) !important;
	.image {
		width: 140px !important;
		height: auto !important;
	}
}

.nav-item:hover .icon,
.nav-item:active .icon {
	opacity: 1;
}

.icon {
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 44px;
	width: 33px;
}

.paused {
	.pause.icon {
		opacity: 0;
		transition: all 1s;
	}
}

.paused:hover {
	.pause.icon {
		opacity: 1;
	}
}

.youtube-link {
	color: white;
}

@media (max-width: 840px) {
	.diff2 {
		display: none;
	}
}

@media (max-width: 690px) {
	.diff1 {
		display: none;
	}
}

@media (min-width: 675px) {
	.side-album {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}


.album-link {
	position: relative;
	top: 4px;
	animation: pulse 3s alternate infinite ease-in;

	&:hover {
		animation-play-state: paused;
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}
	50% {
		transform: scale(1.05);
	}

	100% {
		transform: scale(0.95);
	}
}

.link_img {
	width: 22px;
	transition: all 0.7s;
}

.link_img:hover {
	transform: scale(1.1);
}

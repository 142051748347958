
.page {
	height: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: all 0.7s;
	position: relative;
}

.page:hover {
	.page-name {
		text-decoration: underline;
		transform: scale(1.02);
	}
}

.page-content {
	display: none;
	cursor: default;
	opacity: 0;
	position: absolute;
}

.visible {
	opacity: 1;
	z-index: 10000;
	display: block;
}
